<template>
  <v-container>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

    <v-row>
      <v-col cols="12" md="6" class="gs_reveal gs_reveal_fromLeft">
        <img :src="require('@/assets/maltodextrin.jpg')" />
      </v-col>
      <v-col cols="12" md="6" class="pl-12">
        <h1 class="gs_reveal">Maltodextrin</h1>
        <p class="mt-3 mb-7 gs_reveal">
          is a carbohydrate powder, mixture of monosaccharide, disaccharides,
          and polysaccharides with a moderate sweet taste that is obtained by
          the partial hydrolysis of starch. It is a white, odorless, and
          slightly hygroscopic powder, and it dissolves easily in aqueous
          solution. Universal Komoditi Nusantara produces three types of maltodextrins
          for various applications.
        </p>
        <v-divider class="gs_reveal" />
        <div class="my-10">
          <h2 class="gs_reveal">Application</h2>
          <ul class="gs_reveal mt-2">
            <li>Confectionery: bulking agent, fat replacement</li>
            <li>
              Bakery & snacks: bulking agent, binding agent, increase mouthfeel,
              anti-crystallizing agent, balance sweetness
            </li>
            <li>Non-dairy creamer: bulking agent, increase mouthfeel</li>
            <li>Dairy: bulking agent, increase mouthfeel, reduce sugar</li>
            <li>
              Ice cream: bulking agent, anti-crystallizing agent, balance
              sweetness, fat reduction, increase sweetness
            </li>
            <li>
              Soups & sauces: bulking agent, thickening agent, binding agent,
              fat and sugar reduction
            </li>
            <li>Seasonings: bulking agent, increase mouthfeel</li>
          </ul>
        </div>
        <div class="gs_reveal">
          <v-btn block class="mr-5" color="primary" to="/request-a-quote">Request a Quote</v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <div class="information-text">
          <div class="my-10">
            <h2 class="mb-5 gs_reveal">Specification</h2>
            <v-simple-table class="gs_reveal">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Parameter</th>
                    <th class="text-left">
                      DE 10-12 <br />
                      LE
                    </th>
                    <th class="text-left">
                      DE 13-15 <br />
                      LE
                    </th>
                    <th class="text-left">
                      DE 18-20 <br />
                      LE
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in specification" :key="item.parameter">
                    <td>{{ item.parameter }}</td>
                    <td>{{ item.dataA }}</td>
                    <td>{{ item.dataB }}</td>
                    <td>{{ item.dataC }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <div class="my-10">
            <h2 class="mb-5 gs_reveal">Storage</h2>
            <p class="gs_reveal">
              Store in sealed original packaging in cool and dry condition,
              separated from liquid and products with strong odor to avoid cross
              contamination. Shelf life is 24 months from production date.
            </p>
          </div>
          <div class="my-10">
            <h2 class="mb-5 gs_reveal">Quality Assurance</h2>
            <p class="gs_reveal">
              To meet growing customers requirement of food safety and quality,
              Universal Komoditi Nusantara is certified with these following standards:
            </p>
            <ul class="gs_reveal">
              <li>FSSC 22000 v.4.1</li>
              <li>Halal MUI Certificate</li>
              <li>ISO 9001: 2015</li>
              <li>Kosher Certificate</li>
            </ul>
          </div>
          <div class="my-10">
            <h2 class="mb-5 gs_reveal">Packaging</h2>
            <p class="gs_reveal">
              Universal Komoditi Nusantara maltodextrin is packaged in 25 kgs paper bag.
            </p>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="corn-bg gs_reveal gs_reveal_fromRight">
      <img :src="require('@/assets/corn-2.png')" />
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Maltodextrin",
        disabled: true,
        href: "/product/maltodextrin",
      },
    ],
    specification: [
      {
        parameter: "Moisture Content",
        dataA: "Max. 6%",
        dataB: "Max. 6%",
        dataC: "Max. 6%",
      },
      {
        parameter: "pH (20% Solution)",
        dataA: "4 - 6",
        dataB: "4 - 6",
        dataC: "4 - 6",
      },
      {
        parameter: "Bulk Density",
        dataA: "0.4 - 0.65 g/mL",
        dataB: "0.5 - 0.65 g/mL",
        dataC: "0.5 - 0.75 g/mL",
      },
    ],
  }),
};
</script>
