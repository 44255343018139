<template>
  <div class="product-page">
    <div class="product-content">
      <CornStarch v-if="$route.params.id == 'corn-starch'" />
      <Maltodextrin v-if="$route.params.id == 'maltodextrin'" />
      <HighFructoseCornSyrup
        v-if="$route.params.id == 'high-fructose-corn-syrup'"
      />
      <CornGerm v-if="$route.params.id == 'corn-germ'" />
      <CornGlutenFeed v-if="$route.params.id == 'corn-gluten-feed'" />
      <CornGlutenMeal v-if="$route.params.id == 'corn-gluten-meal'" />
      <CornSteepLiquor v-if="$route.params.id == 'corn-steep-liquor'" />
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import CornStarch from "@/components/Product/CornStarch";
import Maltodextrin from "@/components/Product/Maltodextrin";
import HighFructoseCornSyrup from "@/components/Product/HighFructoseCornSyrup";
import CornGerm from "@/components/Product/CornGerm";
import CornGlutenFeed from "@/components/Product/CornGlutenFeed";
import CornGlutenMeal from "@/components/Product/CornGlutenMeal";
import CornSteepLiquor from "@/components/Product/CornSteepLiquor";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  components: {
    Footer,
    CornStarch,
    Maltodextrin,
    HighFructoseCornSyrup,
    CornGerm,
    CornGlutenFeed,
    CornGlutenMeal,
    CornSteepLiquor,
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Corn Starch",
        disabled: true,
        href: "/product/corn-starch",
      },
    ],
    details: {
      title: "Corn Starch",
      image: "corn-starch.jpg",
      imageBag: "corn-2.png",
    },
  }),
  methods: {
    animateFrom(elem, dir) {
      const direction = dir || 1;
      let x = 0;
      let y = direction * 100;
      const element = elem;
      if (element.classList.contains("gs_reveal_fromLeft")) {
        x = -300;
        y = 0;
      } else if (element.classList.contains("gs_reveal_fromRight")) {
        x = 300;
        y = 0;
      }
      element.style.transform = `translate(${x}px, ${y}px)`;
      element.style.opacity = "0";
      gsap.fromTo(
        element,
        { x, y, autoAlpha: 0 },
        {
          duration: 2.25,
          x: 0,
          y: 0,
          autoAlpha: 1,
          ease: "expo",
          overwrite: "auto",
        }
      );
    },

    hide(elem) {
      gsap.set(elem, { autoAlpha: 0 });
    },
  },

  mounted() {
    window.scrollTo(0, 0);

    gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray(".gs_reveal").forEach((elem) => {
      this.hide(elem); // assure that the element is hidden when scrolled into view

      ScrollTrigger.create({
        trigger: elem,
        onEnter: () => {
          this.animateFrom(elem);
        },
        onEnterBack: () => {
          this.animateFrom(elem, -1);
        },
        onLeave: () => {
          this.hide(elem);
        }, // assure that the element is hidden when scrolled into view
      });
    });
  },
};
</script>

<style>
.container {
  position: relative;
}

.corn-bg {
  position: absolute;
  bottom: -54px;
  right: -225px;
  max-width: 70%;
  opacity: 0.8 !important;
}

/* Styles from the GreenSock website */
.product-content img {
  max-width: 100%;
}
.gs_reveal {
  opacity: 0;
  visibility: hidden;
  will-change: transform, opacity;
}
/* GreenSock end */

.information-text {
  position: relative;
  z-index: 1;
}

@media (max-width: 1264px) {
  .product-page .product-content .pl-12 {
    padding-left: 12px !important;
  }
  .corn-bg {
    right: -125px;
  }
}

@media (max-width: 595px) {
  .product-page .product-content .my-10 {
    margin-top: 24px !important;
    margin-bottom: 12px !important;
  }
  .product-page .product-content .mb-5 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .product-page .product-content .v-btn {
    margin-top: 24px !important;
  }
  .corn-bg {
    right: -75px;
  }
}
</style>
