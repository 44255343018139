<template>
  <v-container>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

    <v-row>
      <v-col cols="12" md="6" class="gs_reveal gs_reveal_fromLeft">
        <img :src="require('@/assets/corn-germ.jpg')" />
      </v-col>
      <v-col cols="12" md="6" class="pl-12">
        <h1 class="gs_reveal">Corn Germ</h1>
        <p class="mt-3 mb-7 gs_reveal">
          is a part of the corn kernel where is separated from the starch, fiber
          and gluten by the extraction process of the corn wet milling industry.
        </p>
        <v-divider class="gs_reveal" />
        <div class="my-10">
          <h2 class="gs_reveal">Application</h2>
          <p class="mt-5 mb-1 gs_reveal">
            Corn germ is widely applied for food and livestock diets.
          </p>
          <p class="mb-1 gs_reveal">Food: Corn oil material</p>
          <p class="mb-1 gs_reveal">
            Feed: Corn germ is an excellent source of energy for ruminant and
            poultry
          </p>
        </div>
        <div class="gs_reveal">
          <v-btn block class="mr-5" color="primary" to="/request-a-quote">Request a Quote</v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <div class="information-text">
          <div class="my-10">
            <h2 class="mb-5 gs_reveal">Commercial Guarantee</h2>
            <p class="mt-5 gs_reveal">
              Corn germ is an excellent source of energy in livestock diets.
            </p>
            <v-simple-table class="gs_reveal">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Property</th>
                    <th class="text-left">Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in specification" :key="item.property">
                    <td>{{ item.property }}</td>
                    <td>{{ item.value }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <div class="my-10">
            <h2 class="mb-5 gs_reveal">Storage</h2>
            <p class="gs_reveal">
              Store in dry condition, separated from liquid or products with
              strong odor to avoid cross contamination, best to use before 6
              months from production date.
            </p>
          </div>
          <div class="my-10">
            <h2 class="mb-5 gs_reveal">Packaging</h2>
            <p class="gs_reveal">
              Net Weight 50 kgs per bag. Plastic woven (polypropylene) bag.
              Sewing method.
            </p>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="corn-bg gs_reveal gs_reveal_fromRight">
      <img :src="require('@/assets/corn-2.png')" />
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Corn Germ",
        disabled: true,
        href: "/product/corn-germ",
      },
    ],
    specification: [
      {
        property: "Moisture content",
        value: "Max. 6%",
      },
      {
        property: "Protein",
        value: "Max. 25%",
      },
      {
        property: "Oil",
        value: "Min. 40%",
      },
    ],
  }),
};
</script>
