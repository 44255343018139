<template>
  <v-container>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

    <v-row>
      <v-col cols="12" md="6" class="gs_reveal gs_reveal_fromLeft">
        <img :src="require('@/assets/glucose-syrup.jpg')" />
      </v-col>
      <v-col cols="12" md="6" class="pl-12">
        <h1 class="gs_reveal">High Fructose Corn Syrup</h1>
        <p class="mt-3 mb-7 gs_reveal">
          is a transparent to light yellow color syrup, obtained by the
          isomerization of glucose syrup. The relative sweetness of high
          fructose corn syrup is 1.2 to 1.6 times compared to sucrose (table
          sugar). Universal Komoditi Nusantara commits to fulfil customers’ needs with
          high standard and high quality fructose syrup.
        </p>
        <v-divider class="gs_reveal" />
        <div class="my-10">
          <p class="subtitle-1 mt-5 gs_reveal">Sweetener/sugar reducer for:</p>
          <ul class="gs_reveal">
            <li>Beverage: tea, coffee, juice, soft drink</li>
            <li>Bakery & snacks: cookie, pastry, bread, jam, jelly</li>
            <li>Dairy: condensed milk, ice cream</li>
          </ul>
        </div>
        <div class="gs_reveal">
          <v-btn block class="mr-5" color="primary" to="/request-a-quote">Request a Quote</v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <div class="information-text">
          <div class="my-10">
            <h2 class="mb-5 gs_reveal">Specification</h2>
            <v-simple-table class="gs_reveal">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Property</th>
                    <th class="text-left">Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in specification" :key="item.property">
                    <td>{{ item.property }}</td>
                    <td>{{ item.value }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <div class="my-10">
            <h2 class="mb-5 gs_reveal">Storage</h2>
            <p class="gs_reveal">
              High fructose corn syrup should be stored in a closed container in
              room temperature of 25-30ºC. In presence of higher temperature,
              product color may change. The product is best to used 1 month
              after being discharged from bulk container.
            </p>
          </div>
          <div class="my-10">
            <h2 class="mb-5 gs_reveal">Quality Assurance</h2>
            <p class="gs_reveal">
              To meet growing customers requirement of food safety and quality,
              Universal Komoditi Nusantara is certified with these following standards:
            </p>
            <ul class="gs_reveal">
              <li>FSSC 22000 v.4.1</li>
              <li>Halal MUI Certificate</li>
              <li>ISO 9001: 2015</li>
              <li>Kosher Certificate</li>
            </ul>
          </div>
          <div class="my-10">
            <h2 class="mb-5 gs_reveal">Packaging</h2>
            <p class="gs_reveal">
              Universal Komoditi Nusantara high fructose corn syrup is available in bulk
              packaging.
            </p>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="corn-bg gs_reveal gs_reveal_fromRight">
      <img :src="require('@/assets/corn-2.png')" />
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "High Fructose Corn Syrup",
        disabled: true,
        href: "/product/high-fructose-corn-syrup",
      },
    ],
    specification: [
      {
        property: "Brix",
        value: "Min. 75",
      },
      {
        property: "Fructose (% dry basis)",
        value: "Min. 55",
      },
    ],
  }),
};
</script>
