<template>
  <v-container>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

    <v-row>
      <v-col cols="12" md="6" class="gs_reveal gs_reveal_fromLeft">
        <img :src="require('@/assets/corn-steep-liquor.jpg')" />
      </v-col>
      <v-col cols="12" md="6" class="pl-12">
        <h1 class="gs_reveal">Corn Steep Liquor</h1>
        <p class="mt-3 mb-7 gs_reveal">
          is a viscous brown color liquid, consisting entirely of the
          water-soluble components of corn steeped in water. Corn steep liquor
          is a co-product of corn wet milling industry. It is rich in soluble
          protein, vitamin, and mineral.
        </p>
        <v-divider class="gs_reveal" />
        <div class="my-10">
          <h2 class="gs_reveal">Application</h2>
          <ul class="gs_reveal mt-2">
            <li>
              Corn steep liquor is used as feed ingredients used in beef cattle
              diets as a source of amino acid and mineral
            </li>
            <li>
              Corn steep liquor is widely applied for growth media in MSG
              industry
            </li>
            <li>
              Corn steep liquor is used as nitrogen source in several medium and
              cell culture condition in the production of antibiotics, yeast,
              and vitamin B12
            </li>
            <li>Corn steep liquor is used in fertilizer</li>
          </ul>
        </div>
        <div class="gs_reveal">
          <v-btn block class="mr-5" color="primary" to="/request-a-quote">Request a Quote</v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <div class="information-text">
          <div class="my-10">
            <h2 class="mb-5 gs_reveal">Commercial Guarantee</h2>
            <v-simple-table class="gs_reveal">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Property</th>
                    <th class="text-left">Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in specification" :key="item.property">
                    <td>{{ item.property }}</td>
                    <td>{{ item.value }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <div class="my-10">
            <h2 class="mb-5 gs_reveal">Storage</h2>
            <p class="gs_reveal">
              Store in close storage at ambient temperature. Avoid from direct
              sun exposure. Best to use before 3 months from production date.
            </p>
          </div>
          <div class="my-10">
            <h2 class="mb-5 gs_reveal">Packaging</h2>
            <p class="gs_reveal">
              Universal Komoditi Nusantara corn steep liquor is available in bulk
              packaging.
            </p>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="corn-bg gs_reveal gs_reveal_fromRight">
      <img :src="require('@/assets/corn-2.png')" />
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Corn Steep Liquor",
        disabled: true,
        href: "/product/corn-steep-liquor",
      },
    ],
    specification: [
      {
        property: "Protein (on DS)",
        value: "Min. 30%",
      },
      {
        property: "Concentration",
        value: "Min. 18% Beo",
      },
    ],
  }),
};
</script>
