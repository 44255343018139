<template>
  <v-container>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

    <v-row>
      <v-col cols="12" md="6" class="gs_reveal gs_reveal_fromLeft">
        <img :src="require('@/assets/corn-starch.jpg')" />
      </v-col>
      <v-col cols="12" md="6" class="pl-12">
        <h1 class="gs_reveal">Corn Starch</h1>
        <p class="mt-3 mb-7 gs_reveal">
          is a fine white powder with neutral odor and taste that is obtained
          from the endosperm of the corn kernel through corn wet milling
          process. Universal Komoditi Nusantara commits to always produce a high-quality
          corn starch to meet customers' standard.
        </p>
        <v-divider class="gs_reveal" />
        <div class="my-10">
          <h2 class="gs_reveal">Application</h2>
          <p class="subtitle-1 mt-5 gs_reveal">Food</p>
          <ul class="gs_reveal">
            <li>
              Bakery & snacks: anti-caking agent, thickening agent, bulking
              agent, dough improvement, increase crispiness
            </li>
            <li>Biscuit: anti-caking agent and binding agent</li>
            <li>
              Meat processing: binding agent, texture stability, fat reduction
            </li>
            <li>Soups & sauces: thickening agent, bulking agent</li>
            <li>Vermicelli: main ingredient</li>
          </ul>
          <p class="subtitle-1 mt-5 gs_reveal">Industrial</p>
          <ul class="gs_reveal">
            <li>Adhesive: binding agent</li>
            <li>Textile: sizing compound</li>
          </ul>
        </div>
        <div class="gs_reveal">
          <v-btn block class="mr-5" color="primary" to="/request-a-quote"
            >Request a Quote</v-btn
          >
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <div class="information-text">
          <div class="my-10">
            <h2 class="mb-5 gs_reveal">Commercial Guarantee</h2>
            <v-simple-table class="gs_reveal">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Property</th>
                    <th class="text-left">Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in specification" :key="item.property">
                    <td>{{ item.property }}</td>
                    <td>{{ item.value }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <div class="my-10">
            <h2 class="mb-5 gs_reveal">Storage</h2>
            <p class="gs_reveal">
              Store in sealed original packaging in cool and dry condition,
              separated from liquid and products with strong odor to avoid cross
              contamination. Shelf life is 24 months from production date.
            </p>
          </div>
          <div class="my-10">
            <h2 class="mb-5 gs_reveal">Quality Assurance</h2>
            <p class="gs_reveal">
              To meet growing customers requirement of food safety and quality,
              Universal Komoditi Nusantara is certified with these following standards:
            </p>
            <ul class="gs_reveal">
              <li>FSSC 22000 v.4.1</li>
              <li>Halal MUI Certificate</li>
              <li>ISO 9001: 2015</li>
              <li>Kosher Certificate</li>
            </ul>
          </div>
          <div class="my-10">
            <h2 class="mb-5 gs_reveal">Packaging</h2>
            <p class="gs_reveal">
              Universal Komoditi Nusantara corn starch is available in two types of
              packaging:
            </p>
            <ul class="gs_reveal">
              <li>Small bag, net weight 25 kgs</li>
              <li>Jumbo bag, net weight 850 kgs</li>
            </ul>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="corn-bg gs_reveal gs_reveal_fromRight">
      <img :src="require('@/assets/corn-2.png')" />
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Corn Starch",
        disabled: true,
        href: "/product/corn-starch",
      },
    ],
    specification: [
      {
        property: "Moisture Content",
        value: "Max. 14%",
      },
      {
        property: "Whiteness",
        value: "Min. 85%",
      },
    ],
  }),
};
</script>
