<template>
  <v-container>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

    <v-row>
      <v-col cols="12" md="6" class="gs_reveal gs_reveal_fromLeft">
        <img :src="require('@/assets/corn-gluten-meal.jpg')" />
      </v-col>
      <v-col cols="12" md="6" class="pl-12">
        <h1 class="gs_reveal">Corn Gluten Meal</h1>
        <p class="mt-3 mb-7 gs_reveal">
          is a co-product of the corn wet-milling process which is produced
          after the starch and germ have been removed and the fiber has been
          separated.
        </p>
        <v-divider class="gs_reveal" />
        <div class="my-10">
          <h2 class="gs_reveal">Application</h2>
          <ul class="gs_reveal mt-2">
            <li>
              Corn gluten meal is widely applied for pet food, aquafeed, and
              livestock diets
            </li>
            <li>
              Corn gluten meal is an excellent source of amino acids for poultry
            </li>
            <li>
              Corn gluten meal is high in xanthophylls. The carotenoid pigments
              creates the yellow colour on the egg yolks and makes broiler skins
              become yellowish
            </li>
            <li>
              Corn gluten meal, with its high protein content, is well suited as
              protein source for fish
            </li>
            <li>
              Corn gluten meal is a common ingredient in many pet foods
            </li>
            <li>
              Corn gluten meal can substitute other feed material in feed
              ingredient
            </li>
          </ul>
        </div>
        <div class="gs_reveal">
          <v-btn block class="mr-5" color="primary" to="/request-a-quote">Request a Quote</v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <div class="information-text">
          <div class="my-10">
            <h2 class="mb-5 gs_reveal">Commercial Guarantee</h2>
            <p class="mt-5 gs_reveal">
              Corn gluten meal is one of high protein sources with an excellent
              amino acid profile.
            </p>
            <v-simple-table class="gs_reveal">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Property</th>
                    <th class="text-left">Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in specification" :key="item.property">
                    <td>{{ item.property }}</td>
                    <td>{{ item.value }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <div class="my-10">
            <h2 class="mb-5 gs_reveal">Storage</h2>
            <p class="gs_reveal">
              Store in dry condition, separated from liquid or products with
              strong odor to avoid cross contamination, best to use before 12
              months from production date.
            </p>
          </div>
          <div class="my-10">
            <h2 class="mb-5 gs_reveal">Packaging</h2>
            <p class="gs_reveal">
              Net weight 50 kgs per bag. Plastic woven (polypropylene) bag.
              Sewing method.
            </p>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="corn-bg gs_reveal gs_reveal_fromRight">
      <img :src="require('@/assets/corn-2.png')" />
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Corn Gluten Meal",
        disabled: true,
        href: "/product/corn-gluten-meal",
      },
    ],
    specification: [
      {
        property: "Moisture Content",
        value: "Max. 10%",
      },
      {
        property: "Crude Protein",
        value: "Min. 60%",
      },
    ],
  }),
};
</script>
